import {ActionType, MarketDataActions} from "./actions";
import {IMarketData} from "../../dto/coingecko";

export function marketDataReducer(state: IMarketData, action: MarketDataActions): IMarketData {
    switch (action.type) {
        case ActionType.SetMarketData:
            return {
                ...state,
                currency: action.payload.currency,
                slp: action.payload.slp,
                axs: action.payload.axs,
                eth: action.payload.eth
            }
        case ActionType.UpdateMarketData:
            return {
                ...state,
                ...action.payload
            }
        case ActionType.ClearMarketData:
            return {
                ...state,
                currency: null,
                slp: null,
                axs: null,
                eth: null
            }
    }
}
