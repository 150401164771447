import React, {useEffect, useState} from 'react';
import './AxieSelectorBox.scss';
import useAxieDetailData from "../../hooks/useAxieDetailData";
import {Button, Paper, TextField} from "@material-ui/core";
import {areBreedable, axieClassColors} from "../../api/utils";
import { BigNumber } from 'bignumber.js';

const AxieSelectorBox: React.FC<any> = ({ onAxieData }) => {
    // https://marketplace.axieinfinity.com/axie?part=back-pumpkin&part=mouth-serious&part=horn-cactus&part=tail-hot-butt&breedCount=0&breedCount=0&class=Plant

    const [selectedAxies, setSelectedAxies] = useState<Array<string>>([]);
    const [axie1, setAxie1] = useState<string>('');
    const [axie2, setAxie2] = useState<string>('');

    const [axieData, axieDataLoading, axieDataError] = useAxieDetailData(selectedAxies);

    const buildMarketplaceURL = (criteria: any) => {
        let url = "https://axie.zone/finder?search=";

        url += `class:${criteria.class.toLowerCase()};`

        url += `part:`

        for (let i = 0; i < criteria.parts.length; i++) {
            if (i === criteria.parts.length - 1) {
                url += `${criteria.parts[i]};`;
            } else {
                url += `${criteria.parts[i]},`;
            }
        }

        url += `breed_count:${criteria.breedCount};`

        url += 'view_genes'

        return url
    }

    const startCalculation = () => {
        if (axie1 && axie2) {
            if (axieData && axieData.length > 0) {
                if (axie1 !== axieData[0]?.id && axie2 !== axieData[1]?.id) {
                    setSelectedAxies([axie1, axie2])
                }
            } else {
                setSelectedAxies([axie1, axie2])
            }
        }
    }

    const getPartColor = (part: any): string => {
        const color = axieClassColors.filter((color) => color.partClass === part.class)[0];
        return color ? color.partColor : '#000';
    }

    useEffect(() => {
        console.log('axieData', axieData);
        onAxieData && onAxieData(axieData);
    }, [axieData])

    return (
        <>
        <div className="axie-selector">
            {
                axieData && axieData[0] && axieData[1] && !areBreedable(axieData[0], axieData[1]) ? (
                    <div className="axies-not-breedable">
                        WARNING: These two axies are related and cannot be bred.
                    </div>
                ) : null
            }
            <div className="axies-wrapper">
                <Paper className="axie-selector__axie">
                    <div className="text-field">
                        <TextField
                            label={"Axie 1 ID"}
                            value={axie1}
                            onChange={(e) => setAxie1(e.target.value)}
                        />
                    </div>
                    {
                        axieData && axieData[0] && (
                            <div className="axie-data">
                                <p className="axie-data__name">{axieData[0].name}</p>
                                <img className="axie-data__image" src={axieData[0].image} alt=""/>
                                {
                                    axieData[0].totalIdenticalResults === 0 ? (
                                        <a href={buildMarketplaceURL({
                                            parts: axieData[0].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                            breedCount: axieData[0].breedCount,
                                            class: axieData[0].class
                                        })} className={"axie-data__no-results"}>No <strong>identical</strong> axies listed for sale</a>
                                    ) : (
                                        <>
                                            <a target="_blank" className="axie-data__similar-link" href={buildMarketplaceURL({
                                                parts: axieData[0].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                                breedCount: axieData[0].breedCount,
                                                class: axieData[0].class
                                            })}>
                                                {axieData[0].totalIdenticalResults} <strong>identical</strong> axies
                                            </a>
                                            <div className="axie-data__similar-results">
                                                {
                                                    axieData[0].identicalResults.map((axie: any) => (
                                                        <a target="_blank" className="axie-data__similar-axie" key={axie.id} href={`https://marketplace.axieinfinity.com/axie/${axie.id}`}>
                                                            <img className={"axie-data__similar-axie__image"} src={axie.image} alt=""/>
                                                            <p className={"axie-data__similar-axie__price"}>Ξ {new BigNumber(axie.auction.currentPrice).dividedBy(10 ** 18).toFixed(4)}</p>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    axieData[0].totalSimilarResults === 0 ? (
                                        <a href={buildMarketplaceURL({
                                            parts: axieData[0].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                            breedCount: 7,
                                            class: axieData[0].class
                                        })} className={"axie-data__no-results"}>No <strong>similar</strong> axies listed for sale</a>
                                    ) : (
                                        <>
                                            <a target="_blank" className="axie-data__similar-link" href={buildMarketplaceURL({
                                                parts: axieData[0].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                                breedCount: 7,
                                                class: axieData[0].class
                                            })}>
                                                {axieData[0].totalSimilarResults} <strong>similar</strong> axies
                                            </a>
                                            <div className="axie-data__similar-results">
                                                {
                                                    axieData[0].similarResults.map((axie: any) => (
                                                        <a target="_blank" className="axie-data__similar-axie" key={axie.id} href={`https://marketplace.axieinfinity.com/axie/${axie.id}`}>
                                                            <img className={"axie-data__similar-axie__image"} src={axie.image} alt=""/>
                                                            <p className={"axie-data__similar-axie__price"}>Ξ {new BigNumber(axie.auction.currentPrice).dividedBy(10 ** 18).toFixed(4)}</p>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                <div className="axie-data__parts">
                                    <h5>Body Parts</h5>
                                    {axieData[0].parts.map((part: any) => (
                                        <div className="axie-data__parts__part" key={part.id}>
                                            <span className={"part-type"}>{part.type}: </span>
                                            <span className={"part-name"} style={{ color: getPartColor(part) }}>{part.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }

                </Paper>
                <Paper className="axie-selector__axie">
                    <div className="text-field">
                        <TextField
                            label={"Axie 2 ID"}
                            value={axie2}
                            onChange={(e) => setAxie2(e.target.value)}
                        />
                    </div>
                    {
                        axieData && axieData[1] && (
                            <div className="axie-data">
                                <p className="axie-data__name">{axieData[1].name}</p>
                                <img className="axie-data__image" src={axieData[1].image} alt=""/>
                                {
                                    axieData[1].totalIdenticalResults === 0 ? (
                                        <a href={buildMarketplaceURL({
                                            parts: axieData[1].parts.map((part: any) => '' + part.id),
                                            breedCount: axieData[1].breedCount,
                                            class: axieData[1].class
                                        })} className={"axie-data__no-results"}>No <strong>identical</strong> axies listed for sale</a>
                                    ) : (
                                        <>
                                            <a target="_blank" className="axie-data__similar-link" href={buildMarketplaceURL({
                                                parts: axieData[1].parts.map((part: any) => '' + part.id),
                                                breedCount: axieData[1].breedCount,
                                                class: axieData[1].class
                                            })}>
                                                {axieData[1].totalIdenticalResults} <strong>identical</strong> axies
                                            </a>
                                            <div className="axie-data__similar-results">
                                                {
                                                    axieData[1].identicalResults.map((axie: any) => (
                                                        <a target="_blank" className="axie-data__similar-axie" key={axie.id} href={`https://marketplace.axieinfinity.com/axie/${axie.id}`}>
                                                            <img className={"axie-data__similar-axie__image"} src={axie.image} alt=""/>
                                                            <p className={"axie-data__similar-axie__price"}>Ξ {new BigNumber(axie.auction.currentPrice).dividedBy(10 ** 18).toFixed(4)}</p>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    axieData[1].totalSimilarResults === 0 ? (
                                        <a href={buildMarketplaceURL({
                                            parts: axieData[1].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                            breedCount: 7,
                                            class: axieData[1].class
                                        })} className={"axie-data__no-results"}>No <strong>similar</strong> axies listed for sale</a>
                                    ) : (
                                        <>
                                            <a target="_blank" className="axie-data__similar-link" href={buildMarketplaceURL({
                                                parts: axieData[1].parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                                                breedCount: 7,
                                                class: axieData[1].class
                                            })}>
                                                {axieData[1].totalSimilarResults} <strong>similar</strong> axies
                                            </a>
                                            <div className="axie-data__similar-results">
                                                {
                                                    axieData[1].similarResults.map((axie: any) => (
                                                        <a target="_blank" className="axie-data__similar-axie" key={axie.id} href={`https://marketplace.axieinfinity.com/axie/${axie.id}`}>
                                                            <img className={"axie-data__similar-axie__image"} src={axie.image} alt=""/>
                                                            <p className={"axie-data__similar-axie__price"}>Ξ {new BigNumber(axie.auction.currentPrice).dividedBy(10 ** 18).toFixed(4)}</p>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                <div className="axie-data__parts">
                                    <h5>Body Parts</h5>
                                    {axieData[1].parts.map((part: any) => (
                                        <div className="axie-data__parts__part" key={part.id}>
                                            <span className={"part-type"}>{part.type}: </span>
                                            <span className={"part-name"} style={{ color: getPartColor(part) }}>{part.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </Paper>
            </div>
            <Button disabled={axieDataLoading} className={"calculate-btn"} onClick={() => startCalculation()}>{ axieDataLoading ? 'Loading...' : 'Get Info'}</Button>
            {
                axieDataError ? (
                    <div className="data-fetch-err-message">An error occurred loading the data, please try again.</div>
                ) : null
            }
        </div>

        </>
    )
}

export default AxieSelectorBox;
