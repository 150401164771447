import React from 'react';
import App from './App';
import {UserProvider} from "./contexts/user";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers'
import {MarketDataProvider} from "./contexts/marketData";


function getWeb3Library(provider: any, connector: any) {
    const library = new Web3Provider(
        provider,
        2020 // Ronin Chain ID - https://www.notion.so/Axie-Ronin-Developer-Information-623c6756391249b5a64d08cffd25ea02
    )

    library.pollingInterval = 15_000
    library.detectNetwork().then((network: any) => {
        console.log('network detected', network);
    })

    return library
}

const Root = () => {
    return (
        <Web3ReactProvider getLibrary={getWeb3Library}>
            <UserProvider>
                <MarketDataProvider>
                    <App/>
                </MarketDataProvider>
            </UserProvider>
        </Web3ReactProvider>
    )
}

export default Root;
