import React, {createContext, useReducer, useContext} from "react";
import {marketDataReducer} from "./reducer";
import {IMarketData} from "../../dto/coingecko";

const initialState: IMarketData = {
    currency: 'usd',
    slp: null,
    axs: null,
    eth: null
}

const MarketDataStateContext = createContext<Partial<IMarketData>>({});
const MarketDataDispatchContext = createContext<React.Dispatch<any>>(() => {});

export const MarketDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(marketDataReducer, initialState)

    return (
        <MarketDataDispatchContext.Provider value={dispatch}>
            <MarketDataStateContext.Provider value={state}>
                { children }
            </MarketDataStateContext.Provider>
        </MarketDataDispatchContext.Provider>
    )
}

export const useMarketDataStateContext = () => useContext(MarketDataStateContext);
export const useMarketDataDispatchContext = () => useContext(MarketDataDispatchContext);
