import {IMarketData} from "../../dto/coingecko";

export enum ActionType {
    SetMarketData,
    UpdateMarketData,
    ClearMarketData
}

export interface SetMarketData {
    type: ActionType.SetMarketData,
    payload: IMarketData
}

export interface UpdateMarketData {
    type: ActionType.UpdateMarketData,
    payload: Partial<IMarketData>
}

export interface ClearMarketData {
    type: ActionType.ClearMarketData
}

export type MarketDataActions = SetMarketData |
    UpdateMarketData |
    ClearMarketData;
