import React from 'react';
import './CTAction.scss';
import BuyMeCoffee from '../../assets/buy_coffee.png';
import EBCGold from '../../assets/ebc_gold.png';
import { Paper } from '@material-ui/core';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {toast} from "react-toastify";

const CTAction = () => {
    const copyToClipboard = (txt: any) => {
        if ("gtag" in window) {
            let wallet = '';
            switch (txt) {
                case 'ronin:59c28ecc56a06422818375e6e419886baf6ea43a':
                    wallet = 'ronin';
                    break;
                case '0xBa304dd54b8DAc1B886202ac5969155ebcB45721':
                    wallet = 'ethereum';
                    break;
                default:
                    wallet = '';
            }

            // @ts-ignore
            gtag('event', 'donate', {
                'wallet': wallet
            });
        }

        const m = document;
        txt = m.createTextNode(txt);
        const w = window;
        const b = m.body;
        b.appendChild(txt);
        // @ts-ignore
        if (b.createTextRange) {
            // @ts-ignore
            var d = b.createTextRange();
            d.moveToElementText(txt);
            d.select();
            m.execCommand('copy');
        }
        else {
            const d = m.createRange();
            const g = w.getSelection;
            d.selectNodeContents(txt);
            // @ts-ignore
            g().removeAllRanges();
            // @ts-ignore
            g().addRange(d);
            m.execCommand('copy');
            // @ts-ignore
            g().removeAllRanges();
        }
        txt.remove();

        toast.success("Copied to clipboard");
    }



    return (
        <>
            {/*<Paper className="ctaction">*/}
            {/*    <p className="title">*/}
            {/*        Lately this website has been getting a lot of daily traffic so if you found it useful, please help support it with one of the options below:*/}
            {/*    </p>*/}
            {/*    <div className="donate-options">*/}
            {/*        <div className="text-block">*/}
            {/*            <p>Buy me a Coffee: </p>*/}
            {/*            <a href="https://buymeacoffee.com/rochatech" target="_blank" rel="noreferrer">*/}
            {/*                <img src={BuyMeCoffee} alt=""/>*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*        <div className="text-block">*/}
            {/*            <p>Donate via PayPal:</p>*/}
            {/*            <form action="https://www.paypal.com/donate" method="post" target="_top">*/}
            {/*                <input type="hidden" name="hosted_button_id" value="JSQ5DKVWP4CE8" />*/}
            {/*                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />*/}
            {/*                <img alt="" src="https://www.paypal.com/en_PT/i/scr/pixel.gif" width="1" height="1" />*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*        <div className="text-block">*/}
            {/*            <p>Or use one of the address below to send me a little tip (click to copy to clipboard).</p>*/}
            {/*            <div className="wallet-info">*/}
            {/*                <p>Ronin Wallet: <span onClick={() => copyToClipboard("ronin:59c28ecc56a06422818375e6e419886baf6ea43a")}>ronin:59c28ecc56a06422818375e6e419886baf6ea43a</span> <FileCopyIcon onClick={() => copyToClipboard("ronin:59c28ecc56a06422818375e6e419886baf6ea43a")}/></p>*/}
            {/*                <p>Ethereum Wallet: <span onClick={() => copyToClipboard("0xBa304dd54b8DAc1B886202ac5969155ebcB45721")}>0xBa304dd54b8DAc1B886202ac5969155ebcB45721</span> <FileCopyIcon onClick={() => copyToClipboard("0xBa304dd54b8DAc1B886202ac5969155ebcB45721")}/></p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Paper>*/}

            <Paper className="ctaction ebc">
                <div className="ebc-wrap">
                    <a className="ebc-img-wrap" href="https://www.elitebreeders.club/?utm_source=rocha">
                        <img src={EBCGold} alt="EBC"/>
                    </a>
                    <div className="title">
                        <p>I've recently partnered with the people at <a href="https://www.elitebreeders.club/?utm_source=rocha">Elite Breeders Club</a> to bring even more useful Axie tools to our community.</p>
                        <p>Check them out at their <a href="https://discord.gg/bRV67Kc77u">EBC Discord Server</a> - they have quite a few free resources and some very useful Axie Pricing Analysis stuff inside.</p>
                    </div>
                </div>
            </Paper>
        </>
    )
}

export default CTAction;
