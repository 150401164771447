import {useUserStateContext} from "../contexts/user";
import {useEffect, useState} from "react";
import {getAXSData, getETHData, getSLPData} from "../api/coingecko";
import {ICoinData} from "../dto/coingecko";
import {APIResponse} from "../api/utils";
import {useMarketDataDispatchContext, useMarketDataStateContext} from "../contexts/marketData";
import {ActionType} from "../contexts/marketData/actions";

function useCoinGeckoData(refetchData?: boolean) {
    const { currency } = useMarketDataStateContext();
    const marketDataDispatch = useMarketDataDispatchContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getSLPData(),
            getAXSData(),
            getETHData()
        ])
            .then(([slpData, axsData, ethData]: Array<APIResponse<ICoinData>>) => {
                marketDataDispatch({
                    type: ActionType.SetMarketData,
                    payload: {
                        currency,
                        slp: slpData.data,
                        axs: axsData.data,
                        eth: ethData.data
                    }
                })
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [refetchData, currency]);

    return [
        loading,
        error
    ]
}

export default useCoinGeckoData;
