import {AxiosResponse} from "axios";

export interface APIResponse<T> {
    error?: any;
    status: number;
    data?: T;
}

export const handleAxiosResponse = (response: AxiosResponse<any>): Promise<APIResponse<any>> => {
    if (response.status === 200) {
        return Promise.resolve(response);
    } else {
        return Promise.resolve({ error: response.statusText, status: response.status })
    }
}

export const handleAxiosError = (error: any) => {
    return { error: error.response.error, status: error.response.status }
}

export const areBreedable = (axie1: any, axie2: any): boolean => {
    //self check
    if (axie1.id == axie2.id) {
        return false;
    }
    //parents check
    if (axie2.matronId == axie1.id || axie2.sireId == axie1.id) {
        return false;
    }
    if (axie1.matronId == axie2.id || axie1.sireId == axie2.id) {
        return false;
    }
    //After checking parents, skip if ether is a tagged axie
    if (axie1.matronId == 0 || axie2.matronId == 0) {
        return true;
    }
    //check siblings
    if (axie1.matronId == axie2.matronId || axie1.matronId == axie2.sireId) {
        return false;
    }
    if (axie1.sireId == axie2.matronId || axie1.sireId == axie2.sireId) {
        return false;
    }
    return true;
}

export const slpBreedCost = [
    900,
    1350,
    2250,
    3600,
    5850,
    9450,
    15300
]

export const axieClassColors = [
    {
        partClass: "Plant",
        partColor: "#5CBE2C"
    },
    {
        partClass: "Reptile",
        partColor: "#E68BE1"
    },
    {
        partClass: "Dusk",
        partColor: "#00928D"
    },
    {
        partClass: "Beast",
        partColor: "#FFB936"
    },
    {
        partClass: "Bug",
        partColor: "#FD554A"
    },
    {
        partClass: "Mech",
        partColor: "#C8BDD4"
    },
    {
        partClass: "Aquatic",
        partColor: "#01B5C6"
    },
    {
        partClass: "Bird",
        partColor: "#FF8ABD"
    },
    {
        partClass: "Dawn",
        partColor: "#B9CFFE"
    }
]

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'usd':
            return '$';
        case 'eur':
            return '€';
        case 'php':
            return '₱';
        case 'aud':
            return 'A$';
        case 'brl':
            return 'R$';
        default:
            return '$'
    }
}


