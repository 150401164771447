import React from 'react';
import './AxTooltip.scss';
import {Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

interface InputProps {
    text: string
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
}

const AxTooltip = ({
    text,
    placement
}: InputProps) => {
    return (
        <Tooltip className={"ax-tooltip"} title={text} placement={placement}>
            <InfoIcon/>
        </Tooltip>
    )
}

export default AxTooltip;
