import {useEffect, useState} from "react";
import {getAxieDetail, getSimilarListedAxies} from "../api/axie";

function useAxieDetailData(selectedAxies: Array<string>) {
    const [tempData, setTempData] = useState<any>();
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        if (selectedAxies.length !== 2) return;
        setLoading(true)
        Promise.all([
            getAxieDetail(selectedAxies[0]),
            getAxieDetail(selectedAxies[1])
        ])
            .then(([axie1Data, axie2Data]) => {
                if (!axie1Data.data.errors && !axie2Data.data.errors) {
                    Promise.all([
                        getSimilarListedAxies({
                            breedCount: [0, axie1Data.data.data.axie.breedCount],
                            classes: [axie1Data.data.data.axie.class],
                            numMystic: [],
                            parts: axie1Data.data.data.axie.parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                            pureness: []
                        }),
                        getSimilarListedAxies({
                            breedCount: [0, axie2Data.data.data.axie.breedCount],
                            classes: [axie2Data.data.data.axie.class],
                            numMystic: [],
                            parts: axie2Data.data.data.axie.parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                            pureness: []
                        }),
                        getSimilarListedAxies({
                            breedCount: [0, 7],
                            classes: [axie1Data.data.data.axie.class],
                            numMystic: [],
                            parts: axie1Data.data.data.axie.parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                            pureness: []
                        }),
                        getSimilarListedAxies({
                            breedCount: [0, 7],
                            classes: [axie2Data.data.data.axie.class],
                            numMystic: [],
                            parts: axie2Data.data.data.axie.parts.filter((part: any) => part.type !== 'Eyes' && part.type !== 'Ears').map((part: any) => '' + part.id),
                            pureness: []
                        })
                    ])
                        .then(([identical1Data, identical2Data, similar1Data, similar2Data]) => {
                            if (!identical1Data.data.errors && !identical2Data.data.errors && !similar1Data.data.errors && !similar2Data.data.errors) {
                                setData([
                                    {
                                        ...axie1Data.data.data.axie,
                                        identicalResults: identical1Data.data.data.axies.results,
                                        totalIdenticalResults: identical1Data.data.data.axies.total,
                                        similarResults: similar1Data.data.data.axies.results,
                                        totalSimilarResults: similar1Data.data.data.axies.total
                                    },
                                    {
                                        ...axie2Data.data.data.axie,
                                        identicalResults: identical2Data.data.data.axies.results,
                                        totalIdenticalResults: identical2Data.data.data.axies.total,
                                        similarResults: similar2Data.data.data.axies.results,
                                        totalSimilarResults: similar2Data.data.data.axies.total
                                    }
                                ])
                            } else {

                            }
                        })
                        .catch(setError)
                        .finally(() => setLoading(false))
                } else {
                    setError('Unable to fetch');
                }

            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [selectedAxies]);

    return [
        data,
        loading,
        error
    ]
}

export default useAxieDetailData;
