import React, {useEffect, useState} from 'react';
import './CalculationResultsBox.scss';
import {Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, TextField} from "@material-ui/core";
import {useMarketDataStateContext} from "../../contexts/marketData";
import {getCurrencySymbol, slpBreedCost} from "../../api/utils";
import { v4 as uuidv4 } from 'uuid';
import AxTooltip from "../AxTooltip/AxTooltip";

const AMOUNT_AXS_PER_BREED = 0.5;
const AUCTION_FEE = 0.0425;

const CalculationResultsBox: React.FC<any> = () => {
    const { currency, slp, axs, eth } = useMarketDataStateContext();
    const [axie1Cost, setAxie1Cost] = useState<any>(0);
    const [axie2Cost, setAxie2Cost] = useState<any>(0);
    const [axie1SalePrice, setAxie1SalePrice] = useState<any>(0);
    const [axie2SalePrice, setAxie2SalePrice] = useState<any>(0);
    const [numberOfBreeds, setNumberOfBreeds] = useState<number>(4);

    const [eggSalePrices, setEggSalePrices] = useState<any>([]);

    const [results, setResults] = useState<any>(null);
    const [totalResults, setTotalResults] = useState<any>(null);
    const [totalProfit, setTotalProfit] = useState<number>(0);
    const [totalProfitInCurrency, setTotalProfitInCurrency] = useState<number>(0);

    const [calculate, setCalculate] = useState<boolean>(false);

    const getSoldAxieProfit = (cost: number, salePrice: number) => {
        const salePriceWithFee = salePrice - (salePrice * AUCTION_FEE);

        return -(cost - salePriceWithFee);
    }

    const doCalculation = () => {
        if (!slp || !axs || !eth || !currency) return;

        const slpPrice = slp.market_data.current_price[currency];
        const ethPrice = eth.market_data.current_price[currency];
        const axsPrice = axs.market_data.current_price[currency];

        let breedCostsSlp: Array<number> = [];
        let breedCostsEth: Array<number> = [];

        let calcResults = [];

        for (let i = 0; i < numberOfBreeds; i++) {
            breedCostsSlp.push((slpBreedCost[i] * 2) + (breedCostsSlp[i - 1] ? breedCostsSlp[i - 1] : 0));
            breedCostsEth.push(((slpBreedCost[i] * 2) * slpPrice / ethPrice) + (AMOUNT_AXS_PER_BREED * axsPrice / ethPrice))
        }

        for (let i = 0; i < numberOfBreeds; i++) {
            calcResults.push({
                id: uuidv4(),
                slpCost: breedCostsSlp[i],
                axsCost: AMOUNT_AXS_PER_BREED * (i + 1),
                ethCost: breedCostsEth[i],
                currencyCost: breedCostsEth[i] * ethPrice
            })
        }

        setResults(calcResults);

        const totalCostInEth = breedCostsEth.reduce((acc, curr) => acc + curr);
        const costPerBreedInEth = totalCostInEth / breedCostsEth.length;
        const totalCostInEthWithParents = -(getSoldAxieProfit(axie1Cost, axie1SalePrice)) + -(getSoldAxieProfit(axie2Cost, axie2SalePrice)) + totalCostInEth;

        const totalCostInCurrency = totalCostInEth * ethPrice;
        const costPerBreedInCurrency = costPerBreedInEth * ethPrice;
        const totalCostInCurrencyWithParents = totalCostInEthWithParents * ethPrice;

        setTotalResults({
            costPerBreedInEth,
            costPerBreedInCurrency,
            totalCostInEth,
            totalCostInCurrency,
            totalCostInEthWithParents,
            totalCostInCurrencyWithParents
        })

        setEggSalePrices(Array(calcResults.length).fill(0))

        setTotalProfit(-(totalCostInEthWithParents).toFixed(4));
        setTotalProfitInCurrency(-(totalCostInEthWithParents) * ethPrice);
    }

    useEffect(() => {
        if (eth && currency && results && totalResults && eggSalePrices.length > 0) {
            const ethPrice = eth.market_data.current_price[currency];
            const eggSales = eggSalePrices.reduce((acc: number, curr: number) => Number(acc) + getSoldAxieProfit(0, Number(curr)), 0);
            setTotalProfit(-(totalResults.totalCostInEthWithParents - eggSales))
            setTotalProfitInCurrency(-(totalResults.totalCostInEthWithParents - eggSales) * ethPrice)
        }
    }, [eggSalePrices, currency, eth])

    useEffect(() => {
       doCalculation()
    }, [calculate, numberOfBreeds, currency, eth])

    return (
        <Paper className="calculation-results">
            <div className="calculation-results__number-of-breeds">
                <FormControl component="fieldset">
                    <FormLabel component="legend"><AxTooltip text={"Assuming the axies start at zero breeds"} placement={"top"}/> Number of Breeds</FormLabel>
                    <RadioGroup aria-label="Number of Breeds" name="breeds" value={String(numberOfBreeds)} onChange={(e) => setNumberOfBreeds(parseInt(e.target.value))}>
                        <FormControlLabel value="1" control={<Radio />} label="1" />
                        <FormControlLabel value="2" control={<Radio />} label="2" />
                        <FormControlLabel value="3" control={<Radio />} label="3" />
                        <FormControlLabel value="4" control={<Radio />} label="4" />
                        <FormControlLabel value="5" control={<Radio />} label="5" />
                        <FormControlLabel value="6" control={<Radio />} label="6" />
                        <FormControlLabel value="7" control={<Radio />} label="7" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="calculation-results__axie-prices">
                <AxTooltip text={"(Optional) How much the parents cost"} placement={"top"}/>
                <TextField
                    label={"Axie 1 Cost (ETH)"}
                    value={axie1Cost}
                    onChange={(e) => setAxie1Cost(e.target.value)}
                />
                <TextField
                    label={"Axie 2 Cost (ETH)"}
                    value={axie2Cost}
                    onChange={(e) => setAxie2Cost(e.target.value)}
                />
            </div>
            <div className="calculation-results__axie-sale-prices">
                <AxTooltip text={"(Optional) How much you expect the parents will sell for after breeding"} placement={"top"}/>
                <TextField
                    label={"Axie 1 Listing Price (ETH)"}
                    value={axie1SalePrice}
                    onChange={(e) => setAxie1SalePrice(e.target.value)}
                />
                <TextField
                    label={"Axie 2 Listing Price (ETH)"}
                    value={axie2SalePrice}
                    onChange={(e) => setAxie2SalePrice(e.target.value)}
                />
            </div>
            <div className="calculation-results__info-message">
                For "listing prices", input the value listed at the Auction.
            </div>
            <div className="calculation-results__info-message">
                If you change the above values, you need to click the button below in order for the values to update
            </div>
            <Button className="calculation-results__apply-btn" onClick={() => setCalculate(c => !c)}>Apply Prices</Button>
            { slp && axs && eth && results && (
                <div className="calculation-results__results">
                    <div className="calculation-results__breed" key={results[results.length - 1].id}>
                        <h6><AxTooltip text={`Total breed cost of ${results.length} breeds`} placement={"top"}/> {results.length} Breeds Cost</h6>
                        <div className="cost-item--parts">
                            <div className="cost-item cost-item__slp">
                                <img className="icon" src={slp.image.thumb} alt=""/>
                                <span className="value">{results[results.length - 1].slpCost}</span>
                            </div>
                            <div className="plus">+</div>
                            <div className="cost-item cost-item__axs">
                                <img className="icon" src={axs.image.thumb} alt=""/>
                                <span className="value">{results[results.length - 1].axsCost}</span>
                            </div>
                            {/*<div className="equals">=</div>*/}
                            {/*<div className="cost-item cost-item__eth">*/}
                            {/*    <img className="icon" src={eth.image.thumb} alt=""/>*/}
                            {/*    <span className="value">{results[results.length - 1].ethCost.toFixed(4)}</span>*/}
                            {/*</div>*/}
                            {/*<div className="cost-item cost-item__currency">*/}
                            {/*    <span className="value">({getCurrencySymbol(currency || '')}{results[results.length - 1].currencyCost.toFixed(2)})</span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {
                        slp && axs && eth && results && totalResults && (
                            <>
                                <div className="calculation-results__total">
                                    <div className="cost-item cost-item__eth">
                                        <span className="description">Cost per Egg: </span>
                                        <img className="icon" src={eth.image.thumb} alt=""/>
                                        <span className="value">{totalResults.costPerBreedInEth?.toFixed(4)}</span>
                                    </div>
                                    <div className="cost-item cost-item__eth">
                                        <span className="description">Total Cost: </span>
                                        <img className="icon" src={eth.image.thumb} alt=""/>
                                        <span className="value">{totalResults.totalCostInEth?.toFixed(4)}</span>
                                    </div>
                                    {
                                        totalResults.totalCostInEthWithParents !== totalResults.totalCostInEth ? (
                                            <div className="cost-item cost-item__eth cost-item__parents">
                                                <span className="description">Total Cost with Parents: </span>
                                                <img className="icon" src={eth.image.thumb} alt=""/>
                                                <span className="value">{totalResults.totalCostInEthWithParents?.toFixed(4)}</span>
                                            </div>
                                        ) : null
                                    }
                                    {/*<span className="value">({getCurrencySymbol(currency || '')}{results[results.length - 1].currencyCost.toFixed(2)})</span>*/}
                                </div>
                                <div className="calculation-results__egg-sale-inputs">
                                    {
                                        results.map((breed: any, idx: number) => (
                                            <div className={"egg-sale-input"} key={breed.id}>
                                                <AxTooltip text={`(Optional) How much will the resulting Axie sell for when it is morphed to adult`} placement={"top"}/>
                                                <TextField
                                                    fullWidth
                                                    label={`Egg ${idx + 1} Listing Price (ETH)`}
                                                    value={eggSalePrices[idx]}
                                                    onChange={(e) => {
                                                        const eggSalePricesArr = [...eggSalePrices];
                                                        eggSalePricesArr[idx] = e.target.value;
                                                        setEggSalePrices(eggSalePricesArr);
                                                    }}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="calculation-results__info-message">
                                    For "listing prices", input the value listed at the Auction.
                                </div>
                                <div className="calculation-results__total-profit">
                                    <div className="cost-item">
                                        <span className="description">Estimated Profit:</span>
                                        <img className="icon" src={eth.image.thumb} alt=""/>
                                        <span className="value"> {totalProfit.toFixed(4)}</span>
                                        <span className="value">({getCurrencySymbol(currency || '')}{totalProfitInCurrency.toFixed(2)})</span>
                                    </div>
                                </div>
                                <div className="calculation-results__info-message">
                                    Includes the 4.25% auction fee
                                </div>
                            </>
                        )
                    }
                </div>
            )}
        </Paper>
    )
}

export default CalculationResultsBox;
