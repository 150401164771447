import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "./components/Header/Header";
import useCoinGeckoData from "./hooks/useCoinGeckoData";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import { network } from "./connectors";
import {Container} from "@material-ui/core";
import {useUserStateContext} from "./contexts/user";
import CurrencyInfoBox from "./components/CurrencyInfoBox/CurrencyInfoBox";
import AxieSelectorBox from "./components/AxieSelectorBox/AxieSelectorBox";
import CalculationResultsBox from "./components/CalculationResultsBox/CalculationResultsBox";
import Footer from "./components/Footer/Footer";
import CTAction from "./components/CTAction/CTAction";

// function getErrorMessage(error: Error) {
//     if (error instanceof NoEthereumProviderError) {
//         return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
//     } else if (error instanceof UnsupportedChainIdError) {
//         return "You're connected to an unsupported network."
//     } else if (
//         error instanceof UserRejectedRequestErrorInjected ||
//         error instanceof UserRejectedRequestErrorWalletConnect ||
//         error instanceof UserRejectedRequestErrorFrame
//     ) {
//         return 'Please authorize this website to access your Ethereum account.'
//     } else {
//         console.error(error)
//         return 'An unknown error occurred. Check the console for more details.'
//     }
// }

const App = () => {
    // const { connector, library, chainId, account, activate, deactivate, active, error } = useWeb3React<Web3Provider>();
    // const [activatingConnector, setActivatingConnector] = React.useState<any>()
    //
    // React.useEffect(() => {
    //     if (activatingConnector && activatingConnector === connector) {
    //         setActivatingConnector(undefined)
    //     }
    // }, [activatingConnector, connector])

    const [axieData, setAxieData] = useState<any>(null);


    return (
        <div className="App">
            <ToastContainer/>
            <Header/>
            <Container>
                <main>
                    <CurrencyInfoBox/>
                    <CTAction/>
                    <div className="calculate-grid">
                        <AxieSelectorBox onAxieData={(data: any) => setAxieData(data)}/>
                        <CalculationResultsBox axieData={axieData}/>
                    </div>
                </main>

            </Container>
            {/*<Footer/>*/}
        </div>
    );
}

export default App;
