import {IUserProfile} from "../../dto/user";

export enum ActionType {
    SetUser,
    DeleteUser,
    UpdateProfile
}

export interface SetUser {
    type: ActionType.SetUser,
    payload: IUserProfile
}

export interface DeleteUser {
    type: ActionType.DeleteUser
}

export interface UpdateProfile {
    type: ActionType.UpdateProfile,
    payload: Partial<IUserProfile>
}

export type UserActions = SetUser |
    DeleteUser |
    UpdateProfile;
