let COIN_GECKO_BASE_URL = "https://api.coingecko.com/api/v3";
let RONINCHAIN_EXPLORER_BASE_URL = "https://explorer.roninchain.com";
let RONIN_API_PROXY_BASE_URL = "https://api.lunaciarover.com";
let RONIN_GRAPHQL_API_BASE_URL = "https://axieinfinity.com/graphql-server-v2/graphql";

export const API = {
    COIN_GECKO: {
        GET_SLP_DATA: COIN_GECKO_BASE_URL + "/coins/smooth-love-potion",
        GET_AXS_DATA: COIN_GECKO_BASE_URL + "/coins/axie-infinity",
        GET_ETH_DATA: COIN_GECKO_BASE_URL + "/coins/ethereum"
    },
    RONINCHAIN: {
        VERIFY_WALLET_ID: (walletId: string) => RONIN_API_PROXY_BASE_URL + `/stats/${walletId}`,
    },
    RONIN_GRAPHQL: RONIN_GRAPHQL_API_BASE_URL
}
