import React from 'react';
import './CurrencyInfoBox.scss';
import useCoinGeckoData from "../../hooks/useCoinGeckoData";
import {Paper} from "@material-ui/core";
import {useMarketDataStateContext} from "../../contexts/marketData";
import {getCurrencySymbol} from "../../api/utils";

const CurrencyInfoBox = () => {
    const [loading, error] = useCoinGeckoData();
    const { currency, slp, axs, eth } = useMarketDataStateContext();

    return (
        <div className="currency">
            <div className="info-box">
                {
                    loading ? (
                        <div className="loader"/>
                    ) : (
                        <>
                            {
                                slp ? (
                                    <Paper className="info-box__item">
                                        <img className="icon" src={slp.image.thumb} alt="SLP"/>
                                        <span className="label">SLP</span>
                                        <span className="value">{currency && getCurrencySymbol(currency)}{currency && slp.market_data.current_price[currency]}</span>
                                    </Paper>
                                ) : (
                                    <div className="loader"/>
                                )
                            }
                            {
                                axs ? (
                                    <Paper className="info-box__item">
                                        <img className="icon" src={axs.image.thumb} alt="AXS"/>
                                        <span className="label">AXS</span>
                                        <span className="value">{currency && getCurrencySymbol(currency)}{currency && axs.market_data.current_price[currency]}</span>
                                    </Paper>
                                ) : (
                                    <div className="loader"/>
                                )
                            }
                            {
                                eth ? (
                                    <Paper className="info-box__item">
                                        <img className="icon" src={eth.image.thumb} alt="ETH"/>
                                        <span className="label">ETH</span>
                                        <span className="value">{currency && getCurrencySymbol(currency)}{currency && eth.market_data.current_price[currency]}</span>
                                    </Paper>
                                ) : (
                                    <div className="loader"/>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default CurrencyInfoBox;
