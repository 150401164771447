import {ActionType, UserActions} from "./actions";
import {IUserProfile} from "../../dto/user";

export function userReducer(state: IUserProfile, action: UserActions): IUserProfile {
    switch (action.type) {
        case ActionType.SetUser:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                accountId: action.payload.accountId
            }
        case ActionType.UpdateProfile:
            return {
                ...state,
                ...action.payload
            }
        case ActionType.DeleteUser:
            return {
                ...state,
                id: ''
            }
    }
}
