import React, {useState} from 'react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import {AppBar, Button, InputBase, Toolbar, Typography} from "@material-ui/core";
import {useUserDispatchContext, useUserStateContext} from "../../contexts/user";
import {ActionType as UserActionType} from "../../contexts/user/actions";
import {ActionType as MarketDataActionType} from "../../contexts/marketData/actions";
import {getProfileByRoninAddress, getSigningMessage, verifyWalletId} from "../../api/ronin";
import {toast} from "react-toastify";
import {useMarketDataDispatchContext, useMarketDataStateContext} from "../../contexts/marketData";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    currencySelector: {

    },
    currencySelectorButton: {
        color: "#FFF"
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '45ch',
            },
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    const { name } = useUserStateContext();
    const { currency } = useMarketDataStateContext();
    const userDispatch = useUserDispatchContext();
    const marketDataDispatch = useMarketDataDispatchContext();

    const [roninWallet, setRoninWallet] = useState('');
    const [loading, setLoading] = useState(false);

    const applyRoninWallet = async () => {
        if (!roninWallet || !roninWallet.startsWith('ronin:')) return toast.error('Invalid wallet ID');

        const walletId = roninWallet.replace('ronin:', '0x');

        setLoading(true);
        const res = await getProfileByRoninAddress(walletId);
        setLoading(false);

        console.log('message', res.data);

        if (res.data.errors) {
            setRoninWallet('');
            return toast.error('Invalid wallet ID')
        }

        localStorage.setItem("walletId", walletId);
        localStorage.setItem("walletName", res.data.data.publicProfileWithRoninAddress.name)

        userDispatch({
            type: UserActionType.SetUser,
            payload: {
                id: walletId,
                accountId: res.data.data.publicProfileWithRoninAddress.accountId,
                name: res.data.data.publicProfileWithRoninAddress.name,
            }
        })
    }

    const logout = () => {
        localStorage.removeItem('walletId');
        localStorage.removeItem('walletName');

        userDispatch({
            type: UserActionType.DeleteUser
        })
    }

    const changeCurrency = (newCurrency: string) => {
        if (newCurrency !== currency) {
            marketDataDispatch({
                type: MarketDataActionType.UpdateMarketData,
                payload: {
                    currency: newCurrency
                }
            })
        }
    }

    return (
        <header>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Axie Breed Cost Calculator
                    </Typography>
                    <div className={classes.currencySelector}>
                        <Button onClick={() => changeCurrency('eur')} className={classes.currencySelectorButton}>EUR</Button>
                        <Button onClick={() => changeCurrency('usd')} className={classes.currencySelectorButton}>USD</Button>
                        <Button onClick={() => changeCurrency('php')} className={classes.currencySelectorButton}>PHP</Button>
                        <Button onClick={() => changeCurrency('brl')} className={classes.currencySelectorButton}>BRL</Button>
                        <Button onClick={() => changeCurrency('aud')} className={classes.currencySelectorButton}>AUD</Button>
                    </div>
                    {/*{*/}
                    {/*    name ? (*/}
                    {/*        <>*/}
                    {/*            <div>{ name }</div>*/}
                    {/*            <Button color="inherit" onClick={logout}>Logout</Button>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <div className={classes.search}>*/}
                    {/*                <InputBase*/}
                    {/*                    disabled={loading}*/}
                    {/*                    value={roninWallet}*/}
                    {/*                    placeholder="Ronin wallet ID"*/}
                    {/*                    classes={{*/}
                    {/*                        root: classes.inputRoot,*/}
                    {/*                        input: classes.inputInput,*/}
                    {/*                    }}*/}
                    {/*                    onChange={(e) => setRoninWallet(e.target.value)}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <Button disabled={loading} color="inherit" onClick={applyRoninWallet}>Login</Button>*/}
                    {/*        </>*/}
                    {/*    )*/}
                    {/*}*/}
                </Toolbar>
            </AppBar>
        </header>
    )
}

export default Header;
