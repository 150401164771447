import React, {createContext, useReducer, useContext} from "react";
import {userReducer} from "./reducer";
import {IUserProfile} from "../../dto/user";

const initialUserState: IUserProfile = {
    id: localStorage.getItem('walletId') || '',
    accountId: '',
    name: localStorage.getItem('walletName') || '',
}

const UserStateContext = createContext<Partial<IUserProfile>>({});
const UserDispatchContext = createContext<React.Dispatch<any>>(() => {});


export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(userReducer, initialUserState)

    return (
        <UserDispatchContext.Provider value={dispatch}>
            <UserStateContext.Provider value={state}>
                { children }
            </UserStateContext.Provider>
        </UserDispatchContext.Provider>
    )
}

export const useUserStateContext = () => useContext(UserStateContext);
export const useUserDispatchContext = () => useContext(UserDispatchContext);
