import {APIResponse, handleAxiosError, handleAxiosResponse} from "./utils";
import {ICoinData} from "../dto/coingecko";
import axios, {AxiosResponse} from "axios";
import {API} from "./index";

export const getSLPData = async (): Promise<APIResponse<ICoinData>> => {
    try {
        const response: AxiosResponse<ICoinData> = await axios.get(API.COIN_GECKO.GET_SLP_DATA);

        return handleAxiosResponse(response)
    } catch (err) {
        return handleAxiosError(err)
    }
}

export const getAXSData = async (): Promise<APIResponse<ICoinData>> => {
    try {
        const response: AxiosResponse<ICoinData> = await axios.get(API.COIN_GECKO.GET_AXS_DATA);

        return handleAxiosResponse(response)
    } catch (err) {
        return handleAxiosError(err)
    }
}

export const getETHData = async (): Promise<APIResponse<ICoinData>> => {
    try {
        const response: AxiosResponse<ICoinData> = await axios.get(API.COIN_GECKO.GET_ETH_DATA);

        return handleAxiosResponse(response)
    } catch (err) {
        return handleAxiosError(err)
    }
}
